import React, { Component } from "react";
import Embedder from "./embedder/Embedder";
class MainComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: null,
    };
  }

  componentDidMount() {
    if (String(window.location.search) !== "") {
      let searchParams = String(window.location.href);
      let paramUlr;
      switch (true) {
        case searchParams.includes("?link"):
          paramUlr = searchParams.split("?link=")[1];
          this.setState({ link: paramUlr });
          break;
        default:
          break;
      }
    }
  }
  render() {
    return (
      <div className="container-fluid">
        <div className="row text-center vh-100 align-items-center">
          <div className="col">
            {this.state.link !== null &&
            typeof this.state.link !== "undefined" ? (
              <Embedder link={this.state.link} />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default MainComponent;
