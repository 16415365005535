import React, { Component } from "react";

class Embedder extends Component {
  constructor(props) {
    super(props);
    this.settings = {
      id: `dropboxjs`,
      appKey: `a5s1rtkj0uu2mmx`,
    };

    this.scriptUrl = `https://www.dropbox.com/static/api/2/dropins.js`;
    this.state = {
      link: this.props.link,
    };
  }

  renderEmbed() {
    if (this.state.link !== null && typeof this.state.link !== "undefined") {
      let el = document.createElement("script");
      el.src = this.scriptUrl;
      el.id = this.settings.id;
      el.setAttribute("data-app-key", this.settings.appKey);
      document.body.appendChild(el);
    }
  }

  componentDidMount() {
    this.renderEmbed();
  }
  render() {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a
        data-height="100vh"
        href={this.state.link}
        className="dropbox-embed"
      ></a>
    );
  }
}

export default Embedder;
